<template>
    <div>
        <CBadge :color="statusClass(row.value)">{{ row.value || '-' }}</CBadge>
    </div>
</template>

<script>
export default {
    props: {
        'row': [Object],
    },
    methods: {
        statusClass(status) {
            if (status == 'partial') {
                return 'warning';
            } else if (status == 'complete' || status == 'shipped') {
                return 'success'
            } else if (status == 'none') {
                return 'danger'
            } else {
                return 'light';
            }
        },
    }
}
</script>